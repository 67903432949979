import { useEffect } from 'react';
import './Transparency.css';

export default function Transparency() {
  useEffect(() => {
    document.querySelector('body').scrollTo(0, 0);
  }, []);
  return (
    <div class="paragraph-container">
      <p class="paragraph">
        It is important to note that, by no means am I a professional in the
        fields of journalism, data science, or impartiality. The sentiment
        analysis tool that I have developed is a general-purpose tool and should
        not be used as a sole source of information.
      </p>
      <p class="paragraph">
        The happiness index generated by this tool is calculated using a formula
        that acts as a weighted average of the sentiment analysis of headlines
        that have been scraped and subsequently translated using the AWS
        service. You can find the list of sources at the following link:{' '}
        <a href="https://github.com/TerenceGrover/HSTW/blob/main/server/data/sourceLinks.json">
          Source Links
        </a>
        . The color on the globe on the front page is then calculated using a
        separate formula to enhance the visual impact of the index.
      </p>
      <div class="formula-transparency">
      <span className="index-txt">Happiness Index Formula</span>
        <br />
        <br />
        <div class="formula-container">
          <span id="p" className="index-txt">
            PositiveIndex
          </span>{' '}
          x 10 -{' '}
          <span id="n" className="index-txt">
            NegativeIndex
          </span>{' '}
          x 10 +{' '}
          <span id="nu" className="index-txt">
            NeutralIndex
          </span>{' '}
          x 2 + ({' '}
          <span id="p" className="index-txt">
            PositiveIndex
          </span>{' '}
          +{' '}
          <span id="n" className="index-txt">
            NegativeIndex
          </span>{' '}
          x 5 ) x{' '}
          <span id="m" className="index-txt">
            MixedIndex
          </span>{' '}
          x 10
        </div>
      </div>
      <div class="formula-transparency">
        <span className="index-txt">Color Formula</span>
        <div class="formula-container">
        <span className="index-txt">RGB color = </span> <br />
          <br />
          If index is in the <span id="p" className="index-txt">happy</span> range, then the color is : <br />
          <br />
          RED  =  1 / | GlobalIndex |
          <br />
          GREEN  =  | GlobalIndex | * 50 + 25 + PositiveIndex <br />
          BLUE  =  | MixedIndex * 255 - NeutralIndex * 255 | / 10,
          <br />
          <br />
          If index is in the <span id="nu" className="index-txt">meh</span> range, then the color is : <br /> <br />
          RED  =  200 <br />
          GREEN  =  200 <br />
          BLUE  =  0 <br />
          <br />
          If index is in the <span id="n" className="index-txt">sad</span> range, then the color is : <br /> <br />
          RED  =  | GlobalIndex | * 50 + 25 + NegativeIndex
          <br />
          GREEN  =  1 / | GlobalIndex |
          <br />
          BLUE  =  | MixedIndex * 255 - NeutralIndex * 255 | / 10,
        </div>
      </div>
      <p class="paragraph">
        It is important to note that the sentiment analysis performed is based
        on the headlines of news articles and may not accurately reflect the
        sentiment of the entire article. Additionally, the translation service
        used may introduce inaccuracies in the sentiment analysis.
      </p>
      <p class="paragraph">
        As the developer of this tool, I am committed to transparency and open
        communication with users. If you have any recommendations or suggestions
        for improvements, I would gladly implement any changes that are
        suggested.
      </p>
    </div>
  );
}
